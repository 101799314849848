import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatListModule } from '@angular/material/list'
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu'
import { Router } from '@angular/router'
import { TranslocoService, TranslocoModule } from '@jsverse/transloco'
import { combineLatestWith, Subscription } from 'rxjs'
import { switchMap } from 'rxjs/operators'

import { IconFill } from '~common/icons'
import { Helper, UserService } from '~core/services'
import { DialogService } from '~core/services/ui/dialog.service'
import { Project } from '~features/projects/models/project.model'
import { UpgradeDialogComponent } from '~features/subscription/components/upgrade-dialog/upgrade-dialog.component'
import { User } from '~features/user/models/user.model'

import { SearchBarComponent } from '../../components/filters/search-bar/search-bar.component'
import { ProjectItemComponent } from '../../components/project-item/project-item.component'
import { ClickStopPropagationDirective } from '../../directives/click-stop-propagation.directive'
import { AlertComponent } from '../../ui/alert/alert.component'
import { AvatarComponent } from '../../ui/avatar/avatar.component'
import { ButtonComponent } from '../../ui/button/button.component'
import { TextComponent } from '../../ui/text/text.component'

@Component({
  selector: 'sb-project-selector',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      @use 'variables' as var;

      $inner-container-width: 350px;
      $inner-container-min-height: 250px;
      $radius-outer: var.$base-radius * 5;
      $radius-inner: var.$base-radius;

      sb-project-selector {
        max-width: 100%;
      }

      .project-selector-menu {
        margin-top: var.$base-spacer * 3;
        margin-right: -10px;
        border-radius: 30px;
      }

      .project-selector {
        display: grid;
        grid-template-rows: auto 1fr;
        width: $inner-container-width;
        min-height: $inner-container-min-height;
        padding: 2px 10px;
        @apply gap-0.5;

        .selected-project {
          background-color: #fff;
          border-radius: $radius-outer $radius-outer $radius-inner $radius-inner;
          padding: 24px 24px 20px;
          overflow: hidden;
        }

        .project-list {
          height: 100%;
          background-color: #fff;
          border-radius: $radius-inner $radius-inner $radius-outer $radius-outer;
          padding-top: 20px;
          overflow: hidden;
          /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
          /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
          /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
          /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
          mat-selection-list {
            &:not(.add-project) {
              max-height: 195px;
              overflow-y: auto;
            }

            padding: 0;
            /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
            /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
            mat-list-item {
              padding: 8px 24px;
            }
          }

          .add-project-container {
            .icon-container {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              border: 2px dashed var.$text-light;
            }
          }
        }
      }
    `,
  ],
  standalone: true,
  imports: [
    ButtonComponent,
    MatMenuModule,
    AvatarComponent,
    TranslocoModule,
    ClickStopPropagationDirective,
    ProjectItemComponent,
    SearchBarComponent,
    MatListModule,
    AlertComponent,
    MatIconModule,
    TextComponent,
  ],
  template: `
    <sb-button variant="menu" [matMenuTriggerFor]="menu" #trigger="matMenuTrigger">
      <div class="flex items-center gap-x-2.5 overflow-hidden">
        <sb-avatar size="xs" [src]="project.picture" [title]="project.name" />
        <span class="text-truncate">{{ project.name }}</span>
      </div>
    </sb-button>

    <!-- Menu template -->
    <mat-menu #menu="matMenu" class="project-selector-menu" xPosition="before">
      <ng-container *transloco="let t; read: 'projects'">
        <div class="project-selector" sbClickStopPropagation>
          <div class="selected-project">
            <sb-project-item [project]="project" avatarSize="lg" gap="md" iconSize="24px" />
            <div class="mt-4 flex justify-end">
              <sb-button variant="square" (sbClick)="onClickProjectSettigs()">{{
                t('actions.ManageProject')
              }}</sb-button>
            </div>
          </div>
          <div class="project-list">
            <!-- Search bar -->
            @if (user.projects.length > 1) {
              <div class="mb-2.5 px-6">
                <sb-search-bar [placeholder]="t('ui.SearchProject')" (search)="onSearch($event)" />
              </div>
            }
            @if (user.projects.length > 1) {
              <mat-selection-list>
                @for (project of projectList; track project) {
                  <mat-list-item (click)="onSelectProject(project)">
                    <sb-project-item gap="lg" titleSize="labelLarge" [project]="project" />
                  </mat-list-item>
                }
                @if (projectList.length === 0) {
                  <mat-list-item disabled>
                    <sb-alert variant="neutral">{{ t('info.NoSearchResults') }}</sb-alert>
                  </mat-list-item>
                }
              </mat-selection-list>
            }
            <div class="add-project-container">
              <mat-selection-list class="add-project">
                <mat-list-item>
                  <div class="flex items-center gap-6" (click)="onNewProject()">
                    <div class="icon-container">
                      <mat-icon [svgIcon]="IconsFill.Add" />
                    </div>
                    <sb-text variant="labelLarge" noMargin>{{ t('actions.AddAnotherProject') }}</sb-text>
                  </div>
                </mat-list-item>
              </mat-selection-list>
            </div>
          </div>
        </div>
      </ng-container>
    </mat-menu>
  `,
})
export class ProjectSelectorComponent implements OnInit, OnDestroy {
  project: Project
  projectList: Project[]
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger
  user: User
  protected readonly IconsFill = IconFill

  private subscriptions: Subscription[] = []

  constructor(
    public helper: Helper,
    private router: Router,
    private userService: UserService,
    private dialogService: DialogService,
    private translateService: TranslocoService,
  ) {}

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe())
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.userService.user.pipe(combineLatestWith(this.userService.currentProject$)).subscribe(([user, project]) => {
        this.user = user
        this.project = project
        this.projectList = user.projects.filter((p) => p._id !== project._id)
      }),
    )
  }

  onClickProjectSettigs() {
    this.router.navigate(['projects', this.project._id, 'settings']).then(() => this.trigger.closeMenu())
  }

  onNewProject() {
    if (this.user.projects.length >= this.user.plan.maxProjects) {
      this.openUpgradeDialog()
      return
    }

    this.router.navigate(['projects', 'new']).then(() => this.trigger.closeMenu())
  }

  onSearch(searchString: string): void {
    this.projectList = this.user.projects.filter((p) => {
      return p._id !== this.project._id && (!searchString || p.name.toLowerCase().includes(searchString.toLowerCase()))
    })
  }

  onSelectProject(project: Project) {
    this.router.navigate(['projects', project._id]).then(() => this.trigger.closeMenu())
  }

  private openUpgradeDialog() {
    this.subscriptions.push(
      this.translateService
        .selectTranslate(`user.subscription.plans.name.${this.user.plan.name}`)
        .pipe(
          switchMap((planName) =>
            this.translateService.selectTranslateObject('alerts', {
              'projectsLimit.content': {
                plan: planName,
                projects: this.user.plan.maxProjects,
              },
            }),
          ),
        )
        .subscribe((alerts) => {
          const data = {
            title: alerts.projectsLimit.title,
            content: alerts.projectsLimit.content,
          }
          this.dialogService.open(UpgradeDialogComponent, { data, width: '835px' })
        }),
    )
  }
}
