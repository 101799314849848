import { NgClass, NgStyle, NgTemplateOutlet, AsyncPipe } from '@angular/common'
import { Component, ContentChild, OnDestroy, OnInit, TemplateRef } from '@angular/core'
import { MatSidenavModule } from '@angular/material/sidenav'
import { Store } from '@ngrx/store'
import { Observable, Subscription } from 'rxjs'

import { selectUrl } from '~core/store/core.selectors'
import { closeMobileMenu } from '~core/store/ui/ui.actions'
import * as fromUI from '~core/store/ui/ui.reducer'
import { selectIsMobileSidebar, selectMobileMenuIsOpen, selectSidebar } from '~core/store/ui/ui.selectors'

import { AppTopbarComponent } from '../app-topbar/app-topbar.component'

@Component({
  selector: 'sb-app-layout',
  template: `
    @if (sidebar$ | async; as sidebar) {
      <sb-app-topbar [showProjectSelector]="isProjectRoute" />
      @if ({ value: isMobileSidebar$ | async }; as isMobile) {
        <mat-sidenav-container>
          <!-- isSidebarEnable is used to check if we need to show mobile or desktop menu -->
          <mat-sidenav
            [opened]="!isMobile.value || (open$ | async)"
            [mode]="isMobile.value ? 'over' : 'side'"
            [disableClose]="!isMobile.value"
            [ngClass]="{ 'is-mobile': isMobile.value }"
            [ngStyle]="{
              width: sidebar.expanded || isMobile.value ? sidebar.expandedSize : sidebar.collapsedSize,
              transition: isMobile.value ? 'none' : 'all 0.2s ease 0s'
            }"
            (closed)="onSidenavClose()"
          >
            @if (sidenavTemplateRef) {
              <ng-container [ngTemplateOutlet]="sidenavTemplateRef"></ng-container>
            }
          </mat-sidenav>
          <mat-sidenav-content
            [ngStyle]="{
              marginLeft: sidebar.expanded || isMobile.value ? sidebar.expandedSize : sidebar.collapsedSize
            }"
          >
            <ng-content></ng-content>
          </mat-sidenav-content>
        </mat-sidenav-container>
      }
    }
  `,
  styles: [
    `
      @use '_variables.scss' as variables;

      :host {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
      }

      mat-sidenav-container {
        height: 100%;

        mat-sidenav {
          background-color: transparent;

          &.is-mobile {
            background-color: variables.$app-background-light;
          }

          &.mat-drawer-side {
            border: none;
          }
        }
      }
    `,
  ],
  standalone: true,
  imports: [AppTopbarComponent, MatSidenavModule, NgClass, NgStyle, NgTemplateOutlet, AsyncPipe],
})
export class AppLayoutComponent implements OnInit, OnDestroy {
  isMobileSidebar$: Observable<boolean>
  isProjectRoute: boolean = false
  open$: Observable<boolean>
  sidebar$: Observable<fromUI.State['sidebar']>
  @ContentChild('sidenav') sidenavTemplateRef: TemplateRef<unknown>
  private sub: Subscription = Subscription.EMPTY

  constructor(private store: Store) {}

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  ngOnInit(): void {
    this.sidebar$ = this.store.select(selectSidebar)
    this.open$ = this.store.select(selectMobileMenuIsOpen)
    this.isMobileSidebar$ = this.store.select(selectIsMobileSidebar)
    this.sub = this.store
      .select(selectUrl)
      .subscribe((url) => (this.isProjectRoute = /^\/projects\/[0-9a-f]{24}/.test(url)))
  }

  onSidenavClose() {
    this.store.dispatch(closeMobileMenu())
  }
}
