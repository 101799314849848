import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

import { AvatarSize } from '~common/ui/avatar/avatar.component'
import { Project } from '~features/projects/models/project.model'

import { AvatarComponent } from '../../ui/avatar/avatar.component'
import { BoxComponent } from '../../ui/box/box.component'
import { TextComponent } from '../../ui/text/text.component'
import { ChannelIconsComponent } from '../channel-icons/channel-icons.component'

@Component({
  selector: 'sb-project-item',
  template: `
    <sb-box direction="row" [gap]="gapPx" items="center">
      <sb-avatar [size]="avatarSize" [src]="project.picture" [title]="project.name" />
      <sb-box grow="1" [gap]="avatarSize === 'lg' ? '6px' : '0'">
        <div class="ml-0.5 max-w-full">
          <sb-text [variant]="titleSize" noMargin truncate>{{ project.name }}</sb-text>
        </div>
        <sb-channel-icons [channelTypes]="project.getChannelTypes()" />
      </sb-box>
    </sb-box>
  `,
  styles: [
    `
      @use 'sass:map';
      @use 'themes/config/palette';

      .icon {
        color: map.get(palette.$secondary, 400);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [BoxComponent, AvatarComponent, TextComponent, ChannelIconsComponent],
})
export class ProjectItemComponent {
  @Input() avatarSize: AvatarSize = 'sm'
  channelIconsMap = {
    facebook: 'facebook',
    'facebook-ad-account': 'campaign',
    'facebook-page': 'facebook',
    instagram: 'instagram',
    'instagram-account': 'instagram',
    'linkedin-organization': 'linkedin',
  }
  @Input() gap: 'sm' | 'md' | 'lg' = 'sm'
  @Input() iconSize: string = '18px'
  @Input() project: Project
  @Input() titleSize: 'labelLarge' | 'bodyMedium' = 'bodyMedium'

  get gapPx(): string {
    switch (this.gap) {
      case 'sm':
        return '12px'
      case 'md':
        return '16px'
      case 'lg':
        return '26px'
    }
  }
}
